import React, { Component } from 'react'
import { Index } from 'elasticlunr'
import { Link } from 'gatsby'

// Search component
export default class SearchBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
    }
  }

  render() {
    return (
      <div className="searchBox">
      <h2>Search Dabacana:</h2>
        <input
          style={{
            position: `relative`,
          }}
          type="text"
          placeholder="Type here"
          value={this.state.query}
          onChange={this.search}
        />
        <ul
          style={{
            position: `absolute`,
            backgroundColor: `white`,
            marginLeft: `0`,
            zIndex: `1000`,
          }}
        >
          {this.state.results.map(page => (
            <li key={page.id}>
              {page.format === 'video' && (
                <Link
                  to={`/videos/` + page.link}
                  dangerouslySetInnerHTML={{
                    __html: page.title,
                  }}
                />
              )}
              {page.format === 'standard' && (
                <Link
                  to={`/articles/` + page.link}
                  dangerouslySetInnerHTML={{
                    __html: page.title,
                  }}
                />
              )}
              {page.format === 'product' && (
                <Link
                  to={`/shop/` + page.link}
                  dangerouslySetInnerHTML={{
                    __html: page.title,
                  }}
                />
              )}
              {/* {`: ` + page.tags.join(`,`)} */}
            </li>
          ))}
        </ul>
      </div>
    )
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}
