import React, { Component } from 'react';
import { Link } from 'gatsby'

class Cart extends Component {
  render() {
    if (this.props.isCartOpen && this.props.checkout.lineItems.length) {
      return (
        <div className="cartBar">
          <div className="wrap">
            <div><Link to="/cart-checkout">Cart</Link></div>
            <div>
              <span>( {this.props.checkout.lineItems.length} Item{this.props.checkout.lineItems.length > 1 ? 's' : null} ) - </span><Link to="/cart-checkout">{`Checkout`}</Link>
            </div>
          </div>
        </div>
      )
    }
    return null
  }
}

export default Cart
