import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux';
import Header from '../components/header'
import Footer from '../components/footer'
import favicon from '../images/icon.png';


import './index.scss'

class Layout extends React.Component {
  render() {
    return (
      <div className="main">
        <Helmet
          title="Dabacana"
          meta={[
            { name: 'description', content: 'Dabacana' },
            { name: 'keywords', content: 'dabacana, dablife' },
          ]}
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
          ]}
        />
        <Header />
        <div className="content wrap" style={this.props.isCartOpen && this.props.checkout.lineItems.length > 0 ? { paddingTop : '180px' } : null }>
          {this.props.children}
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  checkout: state._product.checkout,
  product: state._product,
  isCartOpen: state._cart.isCartOpen
});

const ConnectedLayout = connect(mapStateToProps, { })(Layout);

export default ConnectedLayout
