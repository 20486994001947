import React from 'react'
import { Link } from 'gatsby'
import MediaQuery from 'react-responsive'
import { Menu as MenuIcon, X as CloseIcon } from 'react-feather'
import { connect } from 'react-redux'
import Cart from './cart/cart'
import Search from './search'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      isShopifyCartOpen: false,
    }
  }

  toggleMenu = () => {
    if (!this.state.menuOpen) {
      this.setState({
        menuOpen: true,
      })
    } else {
      this.setState({
        menuOpen: false,
      })
    }
  }

  closeMenu = () => {
    this.setState({
      menuOpen: false,
    })
  }
  render() {
    return (
      <div>
        <header className="header">
          <div className="wrap">
            <MediaQuery query="screen and (max-width: 700px)">
              <div className="mobile-menu">
                <button onClick={() => this.toggleMenu()}>
                  {this.state.menuOpen ? <CloseIcon /> : <MenuIcon />}
                </button>
                {this.state.menuOpen && (
                  <div>
                    <nav>
                      <ul>
                        <li>
                          <Link
                            to="/"
                            exact="true"
                            activeClassName="active"
                            onClick={() => this.closeMenu()}
                          >
                            Latest
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/articles"
                            activeClassName="active"
                            onClick={() => this.closeMenu()}
                          >
                            Articles
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/videos"
                            activeClassName="active"
                            onClick={() => this.closeMenu()}
                          >
                            Videos
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/shop"
                            activeClassName="active"
                            onClick={() => this.closeMenu()}
                          >
                            Shop
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
              <div className="right">
                <Link to="/" className="logo">
                  <svg
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 859.4 148.9"
                  >
                    <path d="M48.5 16.8C39.6 5 37.1.1 11.1 0-7.1 0 2.6 5.5 2.5 28.3c0 5.5.2 67.5.1 89.5s7.5 30.7 47 30.8c39.4.1 54.1-14.6 54.5-31 .3-14.3-6.3-27.2-14.4-40.5-9.5-15.3-30.3-45.8-41.2-60.3zm-.2 101c-8.8.1-12.1-5.2-12.3-11.2-.1-3.9 0-28.4.1-41.6 0-5.5-.9-7.9-1.5-9.2-.6-1.4.7-1.9 1.5-.8 5.2 7.5 26.4 35.8 26.6 48.6 0 7.5-5.1 14.1-14.4 14.2z" />
                    <path d="M192.6 55.9C192.5 16.3 171.1-.4 133.3 0c-37.8.4-52.9 20-53 39.1-.1 19.1 13.5 32.2 30 32.5 16.5.3 29.2-15.3 21.7-15.2-7.5 0-15-4.4-15.2-13.8-.2-9.4 9.2-14.2 19.6-14.1 10.5.1 18.2 6.8 18.1 18.1-.1 25.2-46.6 25.4-46.6 63.3 0 22.1 13.3 39 34.9 39 11.4 0 19.2-2.2 25.1-4.2 5.8-1.9 9.1 1.5 24.6 1.6 13.8.1.2-8.6.1-28.4 0-15.5.1-51.2 0-62zM152 118.3c-8.6 0-13.9-4.7-13.9-11.7 0-8.1 9.5-15.8 17.7-15.8s13.2 5.2 13.2 12c-.1 8.5-8.4 15.5-17 15.5zM376.4 47.6c-.1-32.3-21-47.4-47.7-47.6-26.6-.2-41 16.5-41 31.6 0 15.1 12.5 27.6 25.6 27.7 13.1.1 17.9-8.6 15-9.5-2.8-.9-6.9-3.5-6.9-9.8-.1-6.3 4-11.5 10.2-11.6 6.2-.1 12.3 4.4 12.3 14 0 30.7-62.7 26.3-63.2 68.8-.3 25.9 21.3 37.7 41.2 37.7 11.4 0 18.8-2.5 25.1-4.4 5.8-1.7 11 1.9 29.7 2 14.3.1-.3-10.7-.3-28.7 0-13.3.1-59.5 0-70.2zm-49 70.7c-8.6 0-13.9-4.7-13.9-11.7 0-8.1 9.5-15.8 17.7-15.8s13.2 5.2 13.2 12c-.1 8.5-8.4 15.5-17 15.5zM583.1 51.9c0-30.8-13.5-51.8-51.2-51.9-37.8-.1-49.5 22.9-49.6 37.2-.1 14.4 9.2 29.2 25.8 29.4 16.6.1 28-10.7 20.4-10.7-7.5 0-13.6-4.7-13.7-12.8-.1-10.1 7.9-14.6 16.5-14.8 8.6-.2 16.9 5.8 16.9 17.1-.1 15.4-9.8 22.4-23.5 29.1-21.7 10.6-29.1 26-29.1 37.6 0 21.7 13.5 36.7 36.9 36.7 11 0 17.4-2.6 22.9-4.2 6.1-1.9 8.5 1.7 27.6 1.7 13.2 0 .2-13.1.1-28.4v-66zm-42.6 66.4c-8.6 0-13.9-4.7-13.9-11.7 0-8.1 9.5-15.8 17.7-15.8s13.2 5.2 13.2 12c0 8.5-8.3 15.5-17 15.5zM825.9 81.5c-12.8.1-25 9-25 25.3s11.7 22.7 22.8 22.7c11.1 0 13.5-8.4 15.7-6.8 2.2 1.6-4.8 13.1-19.4 12.8-14.6-.3-26.2-13.2-32.8-36-6.5-22.5-11.8-44.1-18.4-66.9C761.3 6.4 745.3 0 722.9 0S685 14.9 684.8 31.1c-.2 16.2 10.6 28.1 25.7 28.4 16.6.3 23.6-10.4 16.1-11.1-6.9-.7-11.8-4.3-11.7-10 .1-6.1 5-10.2 11.7-10.2 6.7 0 14 6.4 13.8 18.4-.2 12.4-10.2 19.4-22.8 24.2-16.5 6.4-31.8 16.5-31.8 41.5 0 21.8 21.4 36.7 47.4 36.6 18.5-.1 25.2-2.6 32.7-4.8 6.5-1.9 8.6 4.7 36.4 4.6 27.8-.1 50.9-17.7 50.8-38.2-.1-21.1-12.3-29.2-27.2-29zm-91.6 36.8c-8.6 0-13.9-4.7-13.9-11.7 0-8.1 9.5-15.8 17.7-15.8s13.2 5.2 13.2 12c-.1 8.5-8.4 15.5-17 15.5z" />
                    <path d="M282.8 41.4c.2-21.8-12.5-41.6-66-41.4-39.4.2-17.1 15-17 28.3 0 5.5.1 67.5 0 89.5-.1 14.6 7.8 30.8 35.1 30.8 27.4-.1 39.9-19.2 40.3-35.6.3-12.5-8.8-22.7-16.9-34.4 12.2-12.9 24.4-19.8 24.5-37.2zm-51.7 67.8c-.2-4.2 0-13.3 0-16.8 0-2.9 1.4-2.5 4.1-.8 5.8 3.7 13.6 12.2 13.6 17.9-.2 12.5-17.1 14.4-17.7-.3zm5.2-43.8c-3.1 2.5-5.1 2.1-5.2-2.1-.1-4.2 0-18.2 0-24.3.1-16 19.6-12.9 20.1 3.3.4 9.2-8 17.4-14.9 23.1zM490.8 90.3c-1.9-.2-2.6.7-3.7 3.3-5.9 13.2-15.7 24.1-33.5 24.2-18.9.1-34.6-15.6-34.8-44-.2-28.4 19.1-45.7 33.2-45.8 11-.1 12 7 1.5 10.2-13.8 4.2-24.6 13.2-24.7 29.6 0 16.3 9.9 22.9 23.2 22.7 13.3-.2 22.5-11.6 22.6-18.9 0-7.3-5.1-15.5-18.3-15.2-13.2.2-18.3 9.5-15.2 17.6 1.7 4.5-1.6 4.8-3 1.2-4-10.5 1.3-22.4 16.4-26 15.1-3.7 22.7-10.2 22.8-21.5.1-11.3-9.9-27.3-38.8-27.6-28.8-.3-56.3 25.9-56.8 77.7-.4 40.6 29.2 70.6 63.6 70.9 31.9.3 45.4-31.3 47.5-53.8.1-2.8-.2-4.4-2-4.6zM679.3 28.3c0-14.1 12.3-25.6.2-25.7-6 0-27.5.1-33.2 0-12.5-.1 0 10 0 25.7 0 10.8-.1 30.3-.1 40.4 0 2.9.6 9.6 2.7 14.4 1.1 2.6-.3 3.3-1.4 1-.1-.2-.2-.3-.3-.6-3.1-8.4-15.6-43.4-20.1-56.6C622 11.4 636 2.5 625 2.6h-34.6c-14.4 0 0 10.3 0 25.7v84.2c0 13.9 3.5 33.6 12.9 33.7h21.8c12.9.1-.3-14.2-.3-28.4 0-9.9.1-19.2-.1-27.5-.2-6.6-2.3-13.1-3.9-15.8-1.3-2.1.7-3 1.5-1 3.5 8.5 18.2 43.8 26.7 63.7 1.7 4 6.4 9.2 12.3 9.2h18.2c13.9 0 0-12 0-28.4 0-20.1-.2-69.8-.2-89.7zM811.7 52.4c14.7 7.2 13.6 23.5 15.2 23.5 1.6 0-.2-16 13.4-23.4 13.6-7.3 19.1-16.4 19.1-25.2C859.4 11 844.1 0 825.9-.1c-18.2-.1-32 11.1-32.1 27.3.1 9.8 3.1 18 17.9 25.2z" />
                  </svg>
                </Link>
              </div>
            </MediaQuery>
            <MediaQuery query="screen and (min-width: 701px)">
              <div className="left">
                <Link to="/" className="logo">
                  <svg
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 859.4 148.9"
                  >
                    <path d="M48.5 16.8C39.6 5 37.1.1 11.1 0-7.1 0 2.6 5.5 2.5 28.3c0 5.5.2 67.5.1 89.5s7.5 30.7 47 30.8c39.4.1 54.1-14.6 54.5-31 .3-14.3-6.3-27.2-14.4-40.5-9.5-15.3-30.3-45.8-41.2-60.3zm-.2 101c-8.8.1-12.1-5.2-12.3-11.2-.1-3.9 0-28.4.1-41.6 0-5.5-.9-7.9-1.5-9.2-.6-1.4.7-1.9 1.5-.8 5.2 7.5 26.4 35.8 26.6 48.6 0 7.5-5.1 14.1-14.4 14.2z" />
                    <path d="M192.6 55.9C192.5 16.3 171.1-.4 133.3 0c-37.8.4-52.9 20-53 39.1-.1 19.1 13.5 32.2 30 32.5 16.5.3 29.2-15.3 21.7-15.2-7.5 0-15-4.4-15.2-13.8-.2-9.4 9.2-14.2 19.6-14.1 10.5.1 18.2 6.8 18.1 18.1-.1 25.2-46.6 25.4-46.6 63.3 0 22.1 13.3 39 34.9 39 11.4 0 19.2-2.2 25.1-4.2 5.8-1.9 9.1 1.5 24.6 1.6 13.8.1.2-8.6.1-28.4 0-15.5.1-51.2 0-62zM152 118.3c-8.6 0-13.9-4.7-13.9-11.7 0-8.1 9.5-15.8 17.7-15.8s13.2 5.2 13.2 12c-.1 8.5-8.4 15.5-17 15.5zM376.4 47.6c-.1-32.3-21-47.4-47.7-47.6-26.6-.2-41 16.5-41 31.6 0 15.1 12.5 27.6 25.6 27.7 13.1.1 17.9-8.6 15-9.5-2.8-.9-6.9-3.5-6.9-9.8-.1-6.3 4-11.5 10.2-11.6 6.2-.1 12.3 4.4 12.3 14 0 30.7-62.7 26.3-63.2 68.8-.3 25.9 21.3 37.7 41.2 37.7 11.4 0 18.8-2.5 25.1-4.4 5.8-1.7 11 1.9 29.7 2 14.3.1-.3-10.7-.3-28.7 0-13.3.1-59.5 0-70.2zm-49 70.7c-8.6 0-13.9-4.7-13.9-11.7 0-8.1 9.5-15.8 17.7-15.8s13.2 5.2 13.2 12c-.1 8.5-8.4 15.5-17 15.5zM583.1 51.9c0-30.8-13.5-51.8-51.2-51.9-37.8-.1-49.5 22.9-49.6 37.2-.1 14.4 9.2 29.2 25.8 29.4 16.6.1 28-10.7 20.4-10.7-7.5 0-13.6-4.7-13.7-12.8-.1-10.1 7.9-14.6 16.5-14.8 8.6-.2 16.9 5.8 16.9 17.1-.1 15.4-9.8 22.4-23.5 29.1-21.7 10.6-29.1 26-29.1 37.6 0 21.7 13.5 36.7 36.9 36.7 11 0 17.4-2.6 22.9-4.2 6.1-1.9 8.5 1.7 27.6 1.7 13.2 0 .2-13.1.1-28.4v-66zm-42.6 66.4c-8.6 0-13.9-4.7-13.9-11.7 0-8.1 9.5-15.8 17.7-15.8s13.2 5.2 13.2 12c0 8.5-8.3 15.5-17 15.5zM825.9 81.5c-12.8.1-25 9-25 25.3s11.7 22.7 22.8 22.7c11.1 0 13.5-8.4 15.7-6.8 2.2 1.6-4.8 13.1-19.4 12.8-14.6-.3-26.2-13.2-32.8-36-6.5-22.5-11.8-44.1-18.4-66.9C761.3 6.4 745.3 0 722.9 0S685 14.9 684.8 31.1c-.2 16.2 10.6 28.1 25.7 28.4 16.6.3 23.6-10.4 16.1-11.1-6.9-.7-11.8-4.3-11.7-10 .1-6.1 5-10.2 11.7-10.2 6.7 0 14 6.4 13.8 18.4-.2 12.4-10.2 19.4-22.8 24.2-16.5 6.4-31.8 16.5-31.8 41.5 0 21.8 21.4 36.7 47.4 36.6 18.5-.1 25.2-2.6 32.7-4.8 6.5-1.9 8.6 4.7 36.4 4.6 27.8-.1 50.9-17.7 50.8-38.2-.1-21.1-12.3-29.2-27.2-29zm-91.6 36.8c-8.6 0-13.9-4.7-13.9-11.7 0-8.1 9.5-15.8 17.7-15.8s13.2 5.2 13.2 12c-.1 8.5-8.4 15.5-17 15.5z" />
                    <path d="M282.8 41.4c.2-21.8-12.5-41.6-66-41.4-39.4.2-17.1 15-17 28.3 0 5.5.1 67.5 0 89.5-.1 14.6 7.8 30.8 35.1 30.8 27.4-.1 39.9-19.2 40.3-35.6.3-12.5-8.8-22.7-16.9-34.4 12.2-12.9 24.4-19.8 24.5-37.2zm-51.7 67.8c-.2-4.2 0-13.3 0-16.8 0-2.9 1.4-2.5 4.1-.8 5.8 3.7 13.6 12.2 13.6 17.9-.2 12.5-17.1 14.4-17.7-.3zm5.2-43.8c-3.1 2.5-5.1 2.1-5.2-2.1-.1-4.2 0-18.2 0-24.3.1-16 19.6-12.9 20.1 3.3.4 9.2-8 17.4-14.9 23.1zM490.8 90.3c-1.9-.2-2.6.7-3.7 3.3-5.9 13.2-15.7 24.1-33.5 24.2-18.9.1-34.6-15.6-34.8-44-.2-28.4 19.1-45.7 33.2-45.8 11-.1 12 7 1.5 10.2-13.8 4.2-24.6 13.2-24.7 29.6 0 16.3 9.9 22.9 23.2 22.7 13.3-.2 22.5-11.6 22.6-18.9 0-7.3-5.1-15.5-18.3-15.2-13.2.2-18.3 9.5-15.2 17.6 1.7 4.5-1.6 4.8-3 1.2-4-10.5 1.3-22.4 16.4-26 15.1-3.7 22.7-10.2 22.8-21.5.1-11.3-9.9-27.3-38.8-27.6-28.8-.3-56.3 25.9-56.8 77.7-.4 40.6 29.2 70.6 63.6 70.9 31.9.3 45.4-31.3 47.5-53.8.1-2.8-.2-4.4-2-4.6zM679.3 28.3c0-14.1 12.3-25.6.2-25.7-6 0-27.5.1-33.2 0-12.5-.1 0 10 0 25.7 0 10.8-.1 30.3-.1 40.4 0 2.9.6 9.6 2.7 14.4 1.1 2.6-.3 3.3-1.4 1-.1-.2-.2-.3-.3-.6-3.1-8.4-15.6-43.4-20.1-56.6C622 11.4 636 2.5 625 2.6h-34.6c-14.4 0 0 10.3 0 25.7v84.2c0 13.9 3.5 33.6 12.9 33.7h21.8c12.9.1-.3-14.2-.3-28.4 0-9.9.1-19.2-.1-27.5-.2-6.6-2.3-13.1-3.9-15.8-1.3-2.1.7-3 1.5-1 3.5 8.5 18.2 43.8 26.7 63.7 1.7 4 6.4 9.2 12.3 9.2h18.2c13.9 0 0-12 0-28.4 0-20.1-.2-69.8-.2-89.7zM811.7 52.4c14.7 7.2 13.6 23.5 15.2 23.5 1.6 0-.2-16 13.4-23.4 13.6-7.3 19.1-16.4 19.1-25.2C859.4 11 844.1 0 825.9-.1c-18.2-.1-32 11.1-32.1 27.3.1 9.8 3.1 18 17.9 25.2z" />
                  </svg>
                </Link>
                <nav>
                  <ul>
                    <li>
                      <Link
                        to="/"
                        exact="true"
                        activeClassName="active"
                        onClick={() => this.closeMenu()}
                      >
                        Latest
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/articles"
                        activeClassName="active"
                        onClick={() => this.closeMenu()}
                      >
                        Articles
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/videos"
                        activeClassName="active"
                        onClick={() => this.closeMenu()}
                      >
                        Videos
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/shop"
                        activeClassName="active"
                        onClick={() => this.closeMenu()}
                      >
                        Shop
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="right">
                <Search />
              </div>
            </MediaQuery>
          </div>
          <Cart
            isCartOpen={this.props.isCartOpen}
            checkout={this.props.product.checkout}
          />
        </header>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  product: state._product,
  isCartOpen: state._cart.isCartOpen,
})

const ConnectedHeader = connect(
  mapStateToProps,
  {}
)(Header)

export default ConnectedHeader
